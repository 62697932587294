import { useState, useContext } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const DiscountPopup = ({ setState, action, actionType }) => {
    const { t } = useTranslation(["coach-profile"]);
    const { authTokens } = useContext(AuthContext);

    const [promoCode, setPromoCode] = useState("");
    const [errorState, setErrorState] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const onCancel = () => {
        setState(false);
    };

    return (
        <div className="webapp-popup-background">
            <div className="webapp-popup">
                <div className="webapp-popup-warning">
                    <h3 className="webapp-popup-title-normal">{t("Insert your coupon")}</h3>
                </div>
                <div className="webapp-popup-message">
                    {actionType === "applyDiscount" ? (
                        <p>{t("To apply your discount, please insert your coupon")}</p>
                    ) : (
                        <p>{t("")}</p>
                    )}
                </div>
                <input type="text" className="webapp-coachprofile-password-body-input" value={promoCode} onChange={(e) => setPromoCode(e.target.value)}/>
                <hr className="webapp-popup-line" />
                <div className="webapp-popup-buttons">
                    <button onClick={onCancel}className="webapp-createteam-button webapp-createteam-white-button">{t("Cancel")}</button>
                    <button onClick={() => action(promoCode, setErrorState, setErrorMessage)} className="webapp-createteam-button webapp-createteam-blue-button">{t("Confirm")}</button>
                </div>
                {errorState && (
                    <div className="webapp-coachprofile-password-error">
                        <p>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DiscountPopup;
