import { useState, useEffect, useContext } from "react";
import "./TrainingDetailsPage.css";
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import { SessionPageHeader } from '../SessionUC/SessionUC';
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger';
import ExerciseDescription from '../../webAppComponents/ExerciseDescription/ExerciseDescription';

import RedTrash from "../../webAppAssets/red-trash.svg"
import EditPencil from "../../webAppAssets/edit-pencil.svg"
import RedExlcudeButton from "../../webAppAssets/red-x-in-circle.svg";
import ConfirmationPopup from '../../webAppComponents/ConfirmationPopup/Popup'
import ImportItemsPopup from "../../webAppComponents/ImportItemsPopup/ImportItemsPopup"
import { LuClock } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';

const TrainingDetailsPage = () => {
    const { t } = useTranslation(["create-training-page", "session-page"]);

    const { state } = useLocation()
    const navigate = useNavigate()

    const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(0);
    const [currentExerciseId, setCurrentExerciseId] = useState(null);
    const [exercisesAvailable, setExercisesAvailable] = useState(null);
    const [trainingOptions, setTrainingOptions] = useState([]);
    const [sessionDeletePopup, setSessionDeletePopup ] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [addExercisePopup, setAddExercisePopup] = useState(false)
    const [sessionData, setSessionData] = useState({
        session_number: state.dataLoaded ? state.dataLoaded.session_number : "",
        date: state.dataLoaded ? state.dataLoaded.date : "",
        microcycle: {
            cycle_number: state.dataLoaded ? state.dataLoaded.microcycle.cycle_number : "",
        },
        completed: state.dataLoaded ? state.dataLoaded.completed : "",
    });

    const [currentTraining, setCurrentTraining] = useState(null);

    const { authTokens } = useContext(AuthContext);

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const handleSelectedExerciseChange = (id) => {
      setCurrentExerciseId(id);
    };

    const getItems = () => {
        try {
            axios
                .get(process.env.REACT_APP_BACKEND + `training/session/${state.session}`, { headers })
                .then((response) => {
                  //console.log(response.data)
                  setCurrentTraining(response.data)
                  setCurrentExerciseId(response.data.exercises[0].id)
                  
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error(error)
        }
    }

    const getTrainingOptions = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BACKEND + 'training/exercise_options/list', { headers })
            setTrainingOptions(response.data)
        }
        catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getItems()
        getTrainingOptions()
    }, [])

    const getExercises = async () => {
      try {
          const response = await axios.get(process.env.REACT_APP_BACKEND + `training/exercises-not-in-session/${state.session}`, { headers })
          setExercisesAvailable(response.data)
      }
      catch (error) {
          console.error(error)
      }
    }

    useEffect(() => {
      if(exercisesAvailable === null)
        getExercises()
    }, editMode)

    const toStringFormat = (time) => {
      if (typeof time === "string")
        return time

      // from seconds to hh:mm:ss
      let hours = Math.floor(time / 3600);
      if (hours / 10 < 1)
        hours = `0${hours}`

      let minutes = Math.floor((time - (hours * 3600)) / 60);
      if (minutes / 10 < 1)
        minutes = `0${minutes}`

      let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
      if (seconds / 10 < 1)
        seconds = `0${seconds}`

      return (`${hours}:${minutes}:${seconds}`);
    }

    const calculateTotalDuration = (exercises) => {
        if (!exercises) return null;
        let totalDuration = 0;
        exercises.forEach(exercise => {
            // from hh:mm:ss to seconds
            const time_split = exercise.total_duration.split(":")
            totalDuration += (parseInt(time_split[0]) * 3600) + (parseInt(time_split[1]) * 60) + parseInt(time_split[2]);
        });

        return toStringFormat(totalDuration)
    }

    const handleDelete = () => {
      axios.delete(process.env.REACT_APP_BACKEND + `training/session/delete/${state.session}`, {headers})
        .then((response) => {
          navigate('/webapp/sessions')
        })
        .catch((error)=> {
          toast.error("delete-error")
        })
    }

    const toggleEditMode = () => {
      setEditMode(!editMode)
    }

    const toggleExercisesPopUp = () => {
      setAddExercisePopup(!addExercisePopup);
    }

  const handleSessionExerciseRemove = (id) => {
    axios.patch(process.env.REACT_APP_BACKEND + `training/session/exercises-update/${state.session}`, {"exercise" : id}, {headers})
      .then((response) => {
        const filtered_exercises = currentTraining.exercises.filter((exercise) => exercise.id != id)
        setCurrentTraining({
          ...currentTraining,
          exercises: filtered_exercises
        });
        setCurrentExerciseId(filtered_exercises[0].id)
      })
      .catch(() => {
        toast.error(t("delete-error"))
      })
  }

  const handleExerciseDataChange = (field, value) => {
    const exercises_modified =  currentTraining.exercises.map((exercise) => {
      if (exercise.id != currentExerciseId)
        return exercise

      if (field === "principles_of_play" && Array.isArray(exercise[field])) {
          const hasEqualValue = exercise[field].some((item) => item.id === value.id);
          if (hasEqualValue) {
              exercise = {
                  ...exercise,
                  [field]: exercise[field].filter((item) => item.id !== value.id),
              };
          } else {
              exercise = {
                  ...exercise,
                  [field]: [...exercise[field], value],
              };
          }
      }else{
        exercise = {
          ...exercise,
          [field]: value,
        };
      }

      exercise = {
        ...exercise,
        ["changed"]: true,
      };
      return exercise
    })

    setCurrentTraining({
      ...currentTraining,
      exercises: exercises_modified
    })
  };

  const handleSave = () => {
    if (!currentTraining.exercises.some((exercise) => exercise.changed === true)){
      toggleEditMode()
      return
    }

  
    axios.patch(process.env.REACT_APP_BACKEND + `training/session/update/${state.session}`, {currentTraining}, {headers})
      .then((response) => {
        location.reload();
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("delete-error"))
      })
  }

  const handleAddExercise = (exercise_id) => {
    axios.patch(process.env.REACT_APP_BACKEND + `training/session/add-exercise/${state.session}`, {exercise_id}, {headers})
      .then((response) => {
        toast.success("saved")
        location.reload();
      })
      .catch((error) => {
        console.error(error)
        toast.error(t("delete-error"))
      })
  }

    return (
        <div className="webapp-sessioninfo">
            <Sidebar selectedOption="trainings" />
            <div className="webapp-sessioninfo-content">
                <SessionPageHeader sessionData={sessionData} />
                <div className="webapp-session-trainings-header">
                    <div>
                        <TrainingsPageChanger
                            selectedPage={0}
                            pages={[
                                { label: t('Training details', { ns: "session-page" }), path: '/webapp/session/trainings', state: { "session": state.session, "training": sessionData?.training?.id } },
                                { label: t('Load Unit', { ns: "session-page" }), path: '/webapp/SessionUC', state: { "session": state.session, "dataLoaded": sessionData } },
                                { label: t('Forms', { ns: "session-page" }), path: '/webapp/session/forms', state: { "session": state.session, "dataLoaded": sessionData } },
                                { label: t('Players', { ns: "session-page" }), path: '/webapp/SessionEditPlayers', state: { "session": state.session, "dataLoaded": sessionData } },
                            ]}
                        />
                    </div>
                    <div className='webapp-session-trainings-header-items'>
                        <div className="session-trainings-header-titles-container">
                            <h2 className="session-trainings-header-titles ">{t('Total Duration')}</h2>
                        </div>
                        <div className="session-trainings-header-info-container">
                            <div className="session-training-total-duration"> <span className="black-16px-600"><LuClock /> {calculateTotalDuration(currentTraining?.exercises)}</span></div>
                            <div className="session-training-edit-holder">
                            {editMode ?
                              <div className="session-training-edit-holder">
                                <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit webapp-session-players-edit" onClick={handleSave}>
                                  <img src={EditPencil} alt="Edit Button" />{t('Save', { ns: "session-page" })}
                                </button>
                                <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit webapp-session-players-edit" onClick={toggleExercisesPopUp}>
                                  {'+ ' + t('Exercise')}
                                  </button>
                              </div >
                              :
                              <button className="webapp-createteam-button webapp-createteam-white-button webapp-createteam-edit webapp-session-players-edit" onClick={toggleEditMode}>
                                  <img src={EditPencil} alt="Edit Button" />{t('Edit')}
                              </button>
                            }
                              <div className='webapp-injury-delete-button' onClick={() => setSessionDeletePopup(true)}>
                                  <img src={RedTrash} /> <span>{t('delete-session')}</span>
                              </div>
                            </div >
                        </div>
                    </div>
                    <div className="webapp-session-training-exercises-container">
                        <h1> {t('Exercises')} </h1>
                        {sessionDeletePopup ? (<ConfirmationPopup data={{ image: "close", message: [t('delete-session-message'), ""], close: t('Cancel'), advance: t('Delete') }} setState={setSessionDeletePopup} action={handleDelete} />) : null}
              {addExercisePopup && <ImportItemsPopup itemsList={exercisesAvailable} closePopup={toggleExercisesPopUp} importItem={handleAddExercise} />}
                        <div className="webapp-session-training-exercise-content">
                            <div className="webapp-session-players-exercises">
                                {currentTraining?.exercises.slice() // Clone the array to avoid mutating the original order
                                  .map((exercise) => (
                                    <div key={exercise.id} className={`webapp-session-players-exercise ${currentExerciseId === exercise.id ? 'exercise-selected' : ''}`} onClick={() => handleSelectedExerciseChange(exercise.id)}>
                                        <span className="black-16px-600" >{exercise.name}</span>
                                        {currentTraining?.exercises.length > 1 &&
                                            <button onClick={() => handleSessionExerciseRemove(exercise.id)} className={`webapp-session-players-player-delete ${editMode  ? '' : 'nondisplay'}`}> <img src={RedExlcudeButton} alt="Button to delete player" /> </button>
                                        }
                                    </div>
                                ))}
                            </div>
                            {currentExerciseId && (
                                <ExerciseDescription
                                    exercise={currentTraining.exercises.find(exercise => exercise.id === currentExerciseId)}
                                    trainingOptions={trainingOptions}
                                    editMode={editMode}
                                    handleExerciseDataChange={handleExerciseDataChange}
                                />
                            )}
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

}

export default TrainingDetailsPage
