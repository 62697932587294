import React, { useEffect } from 'react';
import './FormsTable.css';
import { useNavigate } from 'react-router-dom';
import concluded from '../../webAppAssets/concluded.svg';
import ongoing from '../../webAppAssets/ongoing.svg';
import answered from '../../assets/answered.svg';
import notAnswered from '../../assets/notAnswered.svg';
import { useTranslation } from 'react-i18next';


function FormsTable({ sessions }) {
    const navigate = useNavigate();
    const { t } = useTranslation(['player-forms']);

    return (
        <table className="webapp-forms-table">
            <thead className='webapp-forms-table-header'>
                <tr>
                    <th className='webapp-forms-table-center' >{t('Session')}</th>
                    <th className='webapp-forms-table-center'>{t('Microcycle')}</th>
                    <th className='webapp-forms-table-left' colSpan="2">{t('Session Date')}</th>
                    <th className='webapp-forms-table-left'>{t('Exercises')}</th>
                    <th className='webapp-forms-table-left' colSpan="2">{t('Forms')}</th>
                </tr>
            </thead>
            <tbody className='webapp-forms-table-body'>
                    {sessions.map((session) => {
                        const isAnyAnsweredFalse = !session.pre_train.answered || !session.post_gym.answered || !session.post_train.answered;

                        const handleButtonClick = () => {
                            const { post_gym, post_train, pre_train } = session;
                            const url = `/webapp/player/QuestionsPlayerConfirmation?ucgym=${post_gym.id}&ucgym_ans=${post_gym.answered}&uctrain=${post_train.id}&uctrain_ans=${post_train.answered}&ucpre=${pre_train.id}&ucpre_ans=${pre_train.answered}`;
                    
                            // Use navigate to go to the new route with the parameters
                            navigate(url);
                        }

                        return(
                        <tr className='webapp-forms-table-body-row'>
                            <td className='webapp-forms-table-center'>{session.session}</td>
                            <td className='webapp-forms-table-center'>{session.microcycle}</td>
                            <td className='webapp-forms-table-left'>
                                {session.date}
                            </td>
                            <td className='webapp-forms-table-left'>
                                {session.completed === true ? (
                                    <img src={concluded} className='webapp-forms-page-player-img-status' alt="Concluded" />
                                ) : (
                                    <img src={ongoing} className='webapp-forms-page-player-img-status' alt="Ongoing" />
                                )}
                            </td>
                            <td className='webapp-forms-table-left'>
                                {session.training_plan}
                            </td>
                            <td className='webapp-forms-table-left'>
                                <div className="webapp-forms-table-answers-values">
                                    <div className="webapp-forms-page-player-answers-row">
                                        <img src={session.pre_train.answered ? answered : notAnswered} alt="Pre-Training" />
                                        <div className={session.pre_train.answered ? "webapp-forms-page-player-row-info-answers-answered" : "webapp-forms-page-player-row-info-answers-not-answered"}>
                                            {t('Pre-Training')}
                                        </div>
                                    </div>
                                    <div className="webapp-forms-page-player-answers-row">
                                        <img src={session.post_gym.answered ? answered : notAnswered} alt="Pre-Training" />
                                        <div className={session.post_gym.answered ? "webapp-forms-page-player-row-info-answers-answered" : "webapp-forms-page-player-row-info-answers-not-answered"}>
                                            {t('Post-Gym')}
                                        </div>
                                    </div>
                                    <div className="webapp-forms-page-player-answers-row">
                                        <img src={session.post_train.answered ? answered : notAnswered} alt="Pre-Training" />
                                        <div className={session.post_train.answered ? "webapp-forms-page-player-row-info-answers-answered" : "webapp-forms-page-player-row-info-answers-not-answered"}>
                                            {t('Post-Training')}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='webapp-forms-table-center'>
                                {isAnyAnsweredFalse && <button className="webapp-forms-table-button" onClick={handleButtonClick}>
                                    {t('Answer')}
                                </button>}
                            </td>
                        </tr>
                    )})}
            </tbody>
        </table>
    );
}

export default FormsTable;
