import './App.css';
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import LandingPage from './pages/LandingPage/LandingPage';
import HomePage from './webAppPages/HomePage/HomePage'; 
import LoginPage from "./webAppPages/LoginPage/LoginPage";
import ResetPasswordPage from "./webAppPages/ResetPasswordPage/ResetPasswordPage";
import RecoverPasswordPage from "./webAppPages/RecoverPasswordPage/RecoverPasswordPage"
import RegisterPage from "./webAppPages/RegisterPage/RegisterPage";
import Sidebar from "./webAppComponents/Sidebar/Sidebar";
import PackagesPage from "./webAppPages/WebAppPackages/WebAppPackages";
import CreateTeamPage from "./webAppPages/CreateTeam/CreateTeam";
import UCPage from "./webAppPages/UCPage/UCPage";
import TrainingsPage from "./webAppPages/TrainingsPage/TrainingsPage";
import GamesIndividualLoadPage from "./webAppPages/GamesIndividualLoadPage/GamesIndividualLoadPage";
import GamesCollectiveLoadPage from "./webAppPages/GamesCollectiveLoadPage/GamesCollectiveLoadPage";
import EvaluationPage from './webAppPages/EvaluationPage/EvaluationPage';
import EvaluationsStatistics from './webAppPages/EvaluationsStatistics/EvaluationsStatistics.jsx';
import PlayerDetailPage from './webAppPages/PlayerDetailPage/PlayerDetailPage';
import ChartComponent from './webAppComponents/ChartsDashboard/index';
import ChartComponent2 from './webAppComponents/ChartsDashboard/index2.jsx';
import ChartComponent3 from './webAppComponents/ChartsDashboard/index3.jsx';
import TreinoChartComponent from './webAppComponents/ChartsTreinosSessoes/index';
import TreinoChartComponent2 from './webAppComponents/ChartsTreinosSessoes/index2.jsx';
import TreinosSessoesPage from './webAppPages/Treinos-Sessoes/Treinos-Sessoes.jsx';
import DashboardPage from './webAppPages/Dashboard/DashboardPage.jsx';
import PlayerFilter from './webAppComponents/ChartsDashboard/filter.jsx';
import SessionsPage from './webAppPages/SessionsPage/SessionsPage';
import CoachProfile from './webAppPages/CoachProfile/CoachProfile';
import InjuriesPage from './webAppPages/InjuriesPage/InjuriesPage';
import InjuryDetail from './webAppPages/InjuryDetail/InjuryDetail';
import InjuriesStatistics from './webAppPages/InjuriesStatistics/InjuriesStatistics.jsx';
import CreateGame from './webAppPages/AddGame/AddGame';
import PlayersPage from './webAppPages/PlayersPage/PlayersPage.jsx';
import PlayerInfo from './webAppPages/PlayerInfo/PlayerInfo.jsx';
import AddPlayer from './webAppPages/AddPlayer/AddPlayer.jsx';

import CardHistory from './webAppPages/CardHistory/CardHistory';
import FullHistory from './webAppPages/FullHistory/FullHistory';
import { SessionPageHeader, SessionUC } from './webAppPages/SessionUC/SessionUC';

import SessionEditPlayers from './webAppPages/SessionEditPlayers/SessionEditPlayers';
import GameStats from './webAppPages/GameStats/GameStats';
import { AuthProvider } from './context/AuthContext';
import CreateTrainingPage from './webAppPages/CreateTrainingPage/CreateTrainingPage';
import PlayerFormsPage from './webAppPages/PlayerFormsPage/PlayerFormsPage';
import ConfirmationQuestionsPlayers from "./webAppPages/QuestionsPlayerPage/QuestionsPlayerPage";
import ChangePackages from './webAppPages/ChangePackages/ChangePackages';
import InsideFolder from './webAppPages/InsideFolder/InsideFolder';
import TrainingDetailsPage from './webAppPages/TrainingDetailsPage/TrainingDetailsPage';
import CreateInjuryPage from './webAppPages/CreateInjuryPage/CreateInjuryPage';
import CreateEvaluationsPage  from './webAppPages/CreateEvaluationsPage/CreateEvaluationsPage';
import { Fragment } from 'react';
import CoachPaint from './webAppComponents/CoachPaint/CoachPaint';
import { ToastContainer} from 'react-toastify';




import CoachRoute from './utils/CoachRoute';
import AnonymousRoute from './utils/AnonymousRoute';
import PlayerRoute from './utils/PlayerRoute';



function App() {
  return (
    <BrowserRouter>
      <Fragment>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />

            <Route path="/webapp" element={<AnonymousRoute />} >
              <Route path="/webapp" element={<HomePage />} />
            </Route>

            <Route path="/webapp" element={<AnonymousRoute />} >
              <Route path="/webapp/login" element={<LoginPage />} />
            </Route>

            <Route path="/webapp" element={<AnonymousRoute />} >
              <Route path="/webapp/recover-password" element={<RecoverPasswordPage />} />
            </Route>

            <Route path="/webapp" element={<AnonymousRoute />} >
              <Route path="/webapp/reset-password/:Uinformation" element={<ResetPasswordPage />} />
            </Route>

            <Route path="/webapp/register" element={<RegisterPage />} />

            <Route path="/webapp/register-packages" element={<PackagesPage selectedLanguage="PT" />} />

            <Route path="/webapp/createteam" element={<CoachRoute />}>
              <Route path="/webapp/createteam" element={<CreateTeamPage selectedLanguage="PT" />} />
            </Route>

            <Route path="/webapp/trainings" element={<CoachRoute />}>
              <Route path="/webapp/trainings" element={<TrainingsPage />} />
            </Route>

            <Route path="/webapp/trainings/create" element={<CoachRoute />} >
              <Route path="/webapp/trainings/create" element={<CreateTrainingPage />} />
            </Route>

            <Route path="/webapp/canvas" element={<CoachRoute />} >
              <Route path="/webapp/canvas" element={<CoachPaint />} />
            </Route>

            <Route path="/webapp/folder" element={<CoachRoute />}>
              <Route path="/webapp/folder/:folderId" element={<InsideFolder />} />
            </Route>

            <Route path="webapp/sessions" element={<CoachRoute />}>
              <Route path="/webapp/sessions" element={<SessionsPage />} />
            </Route>

            <Route path="webapp/players" element={<CoachRoute />}>
              <Route path="/webapp/players" element={<PlayersPage />} />
            </Route>

            <Route path="webapp/player/:id" element={<CoachRoute />}>
              <Route path="/webapp/player/:id" element={<PlayerInfo />} />
            </Route>

            <Route path="webapp/addplayer" element={<CoachRoute />}>
              <Route path="/webapp/addplayer" element={<AddPlayer />} />
            </Route>

            <Route path="/webapp/SessionUC/" element={<CoachRoute />}>
              <Route path="/webapp/SessionUC/" element={<SessionUC />} />
            </Route>

            <Route path="/webapp/session/forms" element={<CoachRoute />}>
              <Route path="/webapp/session/forms" element={<TreinosSessoesPage />} />
            </Route>

            <Route path="/webapp/session/trainings" element={<CoachRoute />}>
              <Route path="/webapp/session/trainings" element={<TrainingDetailsPage />} />
            </Route>

            <Route path="/webapp/SessionEditPlayers" element={<CoachRoute />}>
              <Route path="/webapp/SessionEditPlayers" element={<SessionEditPlayers />} />
            </Route>

            <Route path="webapp/uc" element={<CoachRoute />}>
              <Route path="/webapp/uc" element={<UCPage />} />
            </Route>

            <Route path="/webapp/individual-load" element={<GamesIndividualLoadPage />} >
              <Route path="/webapp/individual-load" element={<GamesIndividualLoadPage />} />
            </Route>

            <Route path="/webapp/collective-load" element={<CoachRoute />}>
              <Route path="/webapp/collective-load" element={<GamesCollectiveLoadPage />} />
            </Route>

            <Route path="/webapp/gamestats" element={<CoachRoute />}>
              <Route path="/webapp/gamestats" element={<GameStats />} />
            </Route>

            <Route path='/webapp/addgame' element={<CoachRoute />}>
              <Route path='/webapp/addgame' element={<CreateGame />} />
            </Route>

            <Route path="/webapp/injuries" element={<CoachRoute />}>
              <Route path="/webapp/injuries" element={<InjuriesPage />} />
            </Route>

            <Route path="/webapp/injuries/create" element={<CoachRoute />} >
              <Route path="/webapp/injuries/create" element={<CreateInjuryPage />} />
            </Route>

            <Route path="/webapp/injury/:injuryId" element={<CoachRoute />}>
              <Route path="/webapp/injury/:injuryId" element={<InjuryDetail />} />
            </Route>

            <Route path="/webapp/injuries/statistics" element={<CoachRoute />}>
              <Route path="/webapp/injuries/statistics" element={<InjuriesStatistics />} />
            </Route>

            <Route path="/webapp/evaluations" element={<CoachRoute />}>
              <Route path="/webapp/evaluations" element={<EvaluationPage />} />
            </Route>
            <Route path="/webapp/evaluations/create" element={<CoachRoute />}>
              <Route path="/webapp/evaluations/create" element={<CreateEvaluationsPage />} />
            </Route> 

            <Route path="/webapp/evaluations-statistics" element={<CoachRoute />}>
              <Route path="/webapp/evaluations-statistics" element={<EvaluationsStatistics />} />
            </Route>

            <Route path="/webapp/player-evaluation/:id" element={<CoachRoute />}>
              <Route path="/webapp/player-evaluation/:id" element={<PlayerDetailPage />} />
            </Route>

            <Route path="/webapp/coachprofile" element={<CoachRoute />}>
              <Route path="/webapp/coachprofile" element={<CoachProfile />} />
            </Route>

            <Route path="/webapp/changeplan" element={<CoachRoute />}>
              <Route path="/webapp/changeplan" element={<ChangePackages />} />
            </Route>

            <Route path="/webapp/cardhistory" element={<CoachRoute />}>
              <Route path="/webapp/cardhistory" element={<CardHistory />} />
            </Route>

            <Route path="/webapp/cardhistory/fullhistory" element={<CoachRoute />}>
              <Route path="/webapp/cardhistory/fullhistory" element={<FullHistory />} />
            </Route>

            <Route path="/webapp/player/forms" element={<PlayerRoute />}>
              <Route path="/webapp/player/forms" element={<PlayerFormsPage />} />
            </Route>

            <Route path="/webapp/player/QuestionsPlayerConfirmation" element={<PlayerRoute />}>
              <Route path="/webapp/player/QuestionsPlayerConfirmation" element={<ConfirmationQuestionsPlayers />} />
            </Route>
                  
            <Route path="/webapp/home" element={<CoachRoute />}>
              <Route path="/webapp/home" element={<DashboardPage />} />
            </Route> 

          </Routes>
          <ToastContainer />
        </AuthProvider>
      </Fragment>
    </BrowserRouter >
  );
}

export default App;
