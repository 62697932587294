import { useMediaQuery } from 'react-responsive';
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Carousel} from 'react-responsive-carousel';
import React, {useState, useEffect, useContext} from "react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import "./ChangePackages.css";
import Axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PopUp from "../../webAppComponents/ConfirmationPopup/Popup";
import ConfirmPasswordPopup from '../../webAppComponents/ConfirmPasswordPopup/ConfirmPasswordPopup';
import AuthContext from '../../context/AuthContext'
import { useTranslation } from "react-i18next";

const ChangePackages = () => {
    const { t } = useTranslation(["change-packages"]);
    const location = useLocation();
    const { username, email, password } = location.state || {};
    const [price_ID, setPrice_ID] = useState("unset");
    const [newPlan, setNewPlan] = useState("unset");
    const [subscription, setSubscription] = useState(
        {"interval": ""}
    );
    const [popup, setPopup] = useState(false);
    const [passwordPopup, setPasswordPopup] = useState(false);
    const navigate = useNavigate();
    const { authTokens } = useContext(AuthContext);

    // Define the headers with the Bearer token
    const headers = {
      Authorization: `Bearer ${authTokens.access}`,
    };

    const handleMonthlyPackage = async () => {
        if (subscription.interval == "month") return

        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_MONTHLY)
        setNewPlan("Monthly")
        setPopup(true)
    }

    const handleSemesterlyPackage = async () => {
        if (subscription.interval == "semestre") return;
        
        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_SEMESTERLY)
        setNewPlan("Semesterly")
        setPopup(true)
    }
    const handleYearlyPackage = async () => {
        if (subscription.interval == "year") return;

        setPrice_ID(process.env.REACT_APP_STRIPE_PRICE_YEARLY)
        setNewPlan("Yearly")
        setPopup(true)
    }
    
    const isMobile = useMediaQuery({ maxWidth: 1100 });

    useEffect(() => {
        // Make a GET request with the specified headers
        Axios.get(process.env.REACT_APP_BACKEND + 'users/subscription', { headers })
            .then((response) => {
                // Handle the successful response here
                setSubscription(response.data.subscription)
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    }, [])

    const handleReturn = () => {
        navigate('/webapp/coachprofile')
    }
        
    const changeSubscription = async () => {
        try {
            const response = await Axios.patch(process.env.REACT_APP_BACKEND + 'users/change-subscription', {
            newPriceId: price_ID, // Replace with the actual price ID
            newPlan: newPlan
        },{ headers });

        navigate('/webapp/coachprofile')
        } catch (error) {
            console.error(error);
        }
    }

    const openPassword = () => {
        setPopup(false)
        setPasswordPopup(true)
    }
        
    return (
    <div className="webapp-changepackages-page">

            <div className="webapp-paint-return">
                <button onClick={handleReturn}><HiOutlineArrowLeft/></button>
                <h1>{t("chooseNewPlan")}</h1>
            </div>
            <div className="webapp-changepackages-container">

                <div className="webapp-packages-items">
                <div className="webapp-packages-grid">
                
                {isMobile ? (
                <div className="packages-carousel">
                <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                stopOnHover={true}
                transitionTime={500}
                interval={20000}
                thumbWidth={90}
                showArrows={false}
                swipeable={true}
                className="webapp-custom-changepackage-carousel"
                >
                <div className={subscription.interval == "month" ? "current package-card" : "package-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{t("packageCardName1")}</h2>
                        
                        <p className="package-description">{t("packageCard1Description1")} 
                        <span className="strong-text">{t("packageCard1Description2")}</span> 
                        {t("packageCard1Description3")}<span className="strong-text">
                        {t("packageCard1Description4")}</span>
                        {t("packageCard1Description5")} <span className="strong-text">
                        {t("packageCard1Description6")}</span>
                        {t("packageCard1Description7")}<span className="strong-text">
                        {t("packageCard1Description8")}</span></p>
                        
                        
                    </div>
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{t("packageCard1Info1")} <p>{t("packageCard1Info2")}</p></span>
                            <p className="free-trial-price">{t("packageCard1Info3")}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{t("packageCard1Info4")}</span>
                            <p className="free-trial-price">{t("packageCard1Info5")}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{t("packageCard1Info6")} <p>{t("packageCard1Info7")}</p></span>
                            <button onClick={handleMonthlyPackage}  className={subscription.interval == "month" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "month" ? t("packageCurrent") : t("packageChange")}</button>
                        </div>
                    </div>
                </div>
                <div className={subscription.interval == "semestre" ? "current changepackage-card" : "changepackage-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{t("packageCardName2")}</h2>
                       
                        <p className="package-description">{t("packageCard2Description1")}
                        <span className="strong-text">{t("packageCard2Description2")}</span>
                        {t("packageCard2Description3")}<span className="strong-text">
                        {t("packageCard2Description4")}</span>
                        {t("packageCard2Description5")}<span className="strong-text">
                        {t("packageCard2Description6")}</span>
                        {t("packageCard2Description7")}<span className="strong-text">
                        {t("packageCard2Description8")}</span></p>
                        
                       
                    </div>    
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{t("packageCard2Info1")} <p>{t("packageCard2Info2")}</p></span>
                            <p className="free-trial-price">{t("packageCard2Info3")}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{t("packageCard2Info4")}</span>
                            <p className="free-trial-price">{t("packageCard2Info5")}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{t("packageCard2Info6")} <p>{t("packageCard2Info7")}</p></span>
                            <button onClick={handleSemesterlyPackage} className={subscription.interval == "semestre" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "semestre" ? t("packageCurrent") : t("packageChange")}</button>
                        </div>
                    </div>
                </div>
                  
                    <div className={subscription.interval == "year" ? "current changepackage-card" : "changepackage-card"}>
                        <div className="package-name">
                        <div className="recommended-package-name">
                            <h2 className="text-36px azul-8">{t("packageCardName3")}</h2>
                            <span className="recommended-text">{t("packageReccomended")}</span>
                        </div>  
                        <p className="package-description">{t("packageCard3Description1")}
                        <span className="strong-text">{t("packageCard3Description2")}</span> 
                        {t("packageCard3Description3")} <span className="strong-text">
                        {t("packageCard3Description4")}</span>
                        {t("packageCard3Description5")} <span className="strong-text">
                        {t("packageCard3Description6")}</span>
                        {t("packageCard3Description7")} <span className="strong-text">
                        {t("packageCard3Description8")}</span></p>
                        
                        
                    </div>    
                        <div className="package-info">
                            <div className="package-info-periodo">
                                <span className="package-info-key strong-text">{t("packageCard3Info1")} <p>{t("packageCard3Info2")}</p></span>
                                <p className="free-trial-price">{t("packageCard3Info3")}</p>
                            </div>
                            <div className="package-info-valor">
                                <span className="package-info-key strong-text">{t("packageCard3Info4")}</span>
                                <p className="free-trial-price">{t("packageCard3Info5")}</p>
                            </div>
                            <div className="package-info-compra">
                                <span className="preco-final">{t("packageCard3Info6")} <p>{t("packageCard3Info7")}</p></span>
                                <button onClick={handleYearlyPackage} className={subscription.interval == "year" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "year" ? t("packageCurrent") : t("packageChange")}</button>
                            </div>
                        </div>
                    </div>
              
                </Carousel>
                </div>
                ) : (
                    <>
                <div className={subscription.interval == "month" ? "current changepackage-card" : "changepackage-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{t("packageCardName1")}</h2>
                        
                        <p className="text-20px cor-preta package-description">{t("packageCard1Description1")} 
                        <span className="strong-text">{t("packageCard1Description2")}</span> 
                        {t("packageCard1Description3")}<span className="strong-text">
                        {t("packageCard1Description4")}</span>
                        {t("packageCard1Description5")} <span className="strong-text">
                        {t("packageCard1Description6")}</span>
                        {t("packageCard1Description7")}<span className="strong-text">
                        {t("packageCard1Description8")}</span></p>
                        
                        
                    </div>
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{t("packageCard1Info1")} <p>{t("packageCard1Info2")}</p></span>
                            <p className="free-trial-price">{t("packageCard1Info3")}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{t("packageCard1Info4")}</span>
                            <p className="free-trial-price">{t("packageCard1Info5")}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{t("packageCard1Info6")} <p>{t("packageCard1Info7")}</p></span>
                            <button onClick={handleMonthlyPackage} className={subscription.interval == "month" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "month" ? t("packageCurrent") : t("packageChange")}</button>
                        </div>
                    </div>
                </div>
                <div className={subscription.interval == "semestre" ? "current changepackage-card" : "changepackage-card"}>
                    <div className="package-name">
                        <h2 className="text-36px azul-8">{t("packageCardName2")}</h2>
                       
                        <p className="text-20px cor-preta package-description">{t("packageCard2Description1")}
                        <span className="strong-text">{t("packageCard2Description2")}</span>
                        {t("packageCard2Description3")}<span className="strong-text">
                        {t("packageCard2Description4")}</span>
                        {t("packageCard2Description5")}<span className="strong-text">
                        {t("packageCard2Description6")}</span>
                        {t("packageCard2Description7")}<span className="strong-text">
                        {t("packageCard2Description8")}</span></p>
                        
                       
                    </div>    
                    <div className="package-info">
                        <div className="package-info-periodo">
                            <span className="package-info-key strong-text">{t("packageCard2Info1")} <p>{t("packageCard2Info2")}</p></span>
                            <p className="free-trial-price">{t("packageCard2Info3")}</p>
                        </div>
                        <div className="package-info-valor">
                            <span className="package-info-key strong-text">{t("packageCard2Info4")}</span>
                            <p className="free-trial-price">{t("packageCard2Info5")}</p>
                        </div>
                        <div className="package-info-compra">
                            <span className="preco-final">{t("packageCard2Info6")} <p>{t("packageCard2Info7")}</p></span>
                            <button onClick={handleSemesterlyPackage}  className={subscription.interval == "semestre" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "semestre" ? t("packageCurrent") : t("packageChange")}</button>
                        </div>
                    </div>
                </div>  
                
                    <div className={subscription.interval == "year" ? "current changepackage-card" : "changepackage-card"}>
                        <div className="package-name">  
                            <h2 className="text-36px azul-8">{t("packageCardName3")}</h2>
                        
                        <p className="text-20px cor-preta package-description">{t("packageCard3Description1")}
                        <span className="strong-text">{t("packageCard3Description2")}</span> 
                        {t("packageCard3Description3")} <span className="strong-text">
                        {t("packageCard3Description4")}</span>
                        {t("packageCard3Description5")} <span className="strong-text">
                        {t("packageCard3Description6")}</span>
                        {t("packageCard3Description7")} <span className="strong-text">
                        {t("packageCard3Description8")}</span></p>
                        
                        
                    </div>    
                        <div className=" package-info">
                            <div className="package-info-periodo">
                                <span className="package-info-key strong-text">{t("packageCard3Info1")} <p>{t("packageCard3Info2")}</p></span>
                                <p className="free-trial-price">{t("packageCard3Info3")}</p>
                            </div>
                            <div className="package-info-valor">
                                <span className="package-info-key strong-text">{t("packageCard3Info4")}</span>
                                <p className="free-trial-price">{t("packageCard3Info5")}</p>
                            </div>
                            <div className="package-info-compra">
                                <span className="preco-final">{t("packageCard3Info6")} <p>{t("packageCard3Info7")}</p></span>
                                <button onClick={handleYearlyPackage} className={subscription.interval == "year" ? "webapp-botao-comprar-changepackage " : "enable webapp-botao-comprar-changepackage "}>{subscription.interval == "year" ? t("packageCurrent") : t("packageChange")}</button>
                            </div>
                        </div>
                    </div>
                    
                </>
                )}
                </div>
                {subscription && 
                    <div className='webapp-packages-current-subscription'>
                        <h2 className={`webapp-packages-current-subscription-title ${subscription.interval}`}>{t("packageCurrent")}</h2>
                    </div>
                }
            </div>
        </div>
        {popup && <PopUp data={{
            image: "advance",
            title: t("popupTitle"),
            message: [`${t("popupMessage1")} ${subscription.current_period_end} ${t("popupMessage2")}`],
            close: t("cancel"),
            advance: t("continue")
        }} setState={setPopup} action={openPassword} />}
        {passwordPopup && <ConfirmPasswordPopup
            setState={setPasswordPopup}
            action={changeSubscription} />
        }
    </div>
    )
}

export default ChangePackages;