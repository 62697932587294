import "./AddPlayerSessionExercisePopup.css"
import { useState } from "react"
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import MultipleChoiceDropdown from '../MultipleChoiceDropdown/MultipleChoiceDropdown';

const AddPlayerSessionExercisePopup = ({setaddPlayer, missingPlayers, selectedPlayers, setSelectedPlayers, handlePostSubmit, setReadyToAdd, t}) => {
    const[openDropdown, setOpenDropdown] = useState(false)

    const handleDropdown = () => {
        if (missingPlayers.players.length > 0)
            setOpenDropdown(!openDropdown);
    }

    const handleCancel = () => {
        setaddPlayer(false)
        setOpenDropdown(false)
        setSelectedPlayers({
            players: []
        })
    }

    // Nao sei se tudo isso é necessario, mas é o que está no MultipleChoiceDropdown.jsx
    const handleChange = (field, value) => {
        setSelectedPlayers((prevData) => {
            const isValueEqual = JSON.stringify(prevData[field]) === JSON.stringify(value);
    
            let updatedData;
    
            if (isValueEqual) {
                updatedData = {
                    ...prevData,
                    [field]: null,
                };
            } else {
                if (field === "players" && Array.isArray(prevData[field])) {
                    const hasEqualValue = prevData[field].some((item) => item.id === value.id);
    
                    if (hasEqualValue) {
                        updatedData = {
                            ...prevData,
                            [field]: prevData[field].filter((item) => item.id !== value.id),
                        };
                    } else {
                        updatedData = {
                            ...prevData,
                            [field]: [...prevData[field], value],
                        };
                    }
                } else {
                    updatedData = {
                        ...prevData,
                        [field]: value,
                    };
                }
            }
    
            // Do any other logic related to the state update here
            return updatedData;
        });
    };

    const handleSubmit = () => {
        setReadyToAdd(true)
        setaddPlayer(false)
        setOpenDropdown(false)
    }

       
    return (
        <div className="webapp-addplayer-session-exercise-popup">
            <p className="webapp-addplayer-session-exercise-popup-header">{t('Please, choose the player(s) you want to add to the exercise')}</p>
            <div className="webapp-addplayer-session-exercise-popup-body">
                <h3>{t('Players')} <span className="webapp-asterisco">*</span></h3>
                <div >
                    <div className="create-trainings-exercise-elements-custom-dropdown webapp-addplayer-dropdown-textholder black-16px-400"  onClick={handleDropdown}>
                        <p>{selectedPlayers.players.length === 0
                            ? t('Players')
                            : selectedPlayers.players.map(jogador => jogador.name).join(', ')}</p>
                        {openDropdown ? <BiSolidUpArrow/> : <BiSolidDownArrow/>}
                    </div>
                    {openDropdown && <MultipleChoiceDropdown dropdownOptions={missingPlayers.players} currentExercise={selectedPlayers} handleExerciseDataChange={handleChange} valueToChange="players" label="Jogadores" typeOfKey="foreignKey" t={t}/>}
                </div>
            </div>
            {!openDropdown && <div className="webapp-addplayer-session-exercise-popup-dropwdown-holder"></div>}
            <div className="webapp-addplayer-session-exercise-popup-buttons">
                    <button onClick={handleCancel} className="cancel-button" >
                    {t('Cancel')}
                    </button>
                    <button onClick={handleSubmit}>
                    {t('Confirm')}
                    </button>
            </div>
        </div>
        
    
    );
};

export default AddPlayerSessionExercisePopup;
