import "./QuestionsPlayerPage.css";
import NavbarJogador from "../../webAppComponents/NavbarJogadorQuestionario/NavbarJogadorQuestionario";
import questionArrow from "../../webAppAssets/question-arrow.svg";
import sucessLogo from "../../webAppAssets/sucess-logo.svg";
import seta from "../../webAppAssets/seta-next-page.svg";
import Forms from "../../webAppComponents/Forms/Forms";
import { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import Password from "../CoachProfile/Password";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


const loadDefaultValues = () => {
    return {
        preTreino: {
            qualidadeSono: 5,
            disponibilidade: 5,
            fadiga: 5,
            catedral: 5,
        },
        posTreinoGym: {
            percecaoEsforco: 5,
        },
        posTreinoCampo: {
            percecaoEsforco: 5,
        },
    };
};

const FormsPlayer = () => {

    const location = useLocation();
    const { t } = useTranslation(['player-forms']);

    const ucgymID = new URLSearchParams(location.search).get("ucgym");
    const uctotalID = new URLSearchParams(location.search).get("uctrain");
    const ucpreID = new URLSearchParams(location.search).get("ucpre");

    const [ucpre_answered, setUcpreAnswered] = useState(new URLSearchParams(location.search).get("ucpre_ans"));
    const [uctotal_answered, setUctotalAnswered] = useState(new URLSearchParams(location.search).get("uctrain_ans"));
    const [ucgym_answered, setUcgymAnswered] = useState(new URLSearchParams(location.search).get("ucgym_ans"));
    const [page, setPage] = useState(0);
    const [name, setName] = useState('');
    const [selectedValues, setSelectedValues] = useState(() => loadDefaultValues());
    const [password, setPassword] = useState(false);
    const passwordRef = useRef(null);

    const { authTokens } = useContext(AuthContext);
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };


    const handleEnviarQuestionario = async () => {
        const respostas = JSON.parse(JSON.stringify(selectedValues));
        if (page == 0) {
            try {
                const response = await axios.patch(process.env.REACT_APP_BACKEND + `training/player-pre_session_form/${ucpreID}`, {
                    sleep_quality: respostas.preTreino.qualidadeSono,
                    fatigue: respostas.preTreino.fadiga,
                    doms: respostas.preTreino.catedral,
                    readiness: respostas.preTreino.disponibilidade,
                    awnsered: true,
                }, {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authTokens.access}` }
                });
                if (response.status === 200) {
                    // setPage(page + 1);
                    setUcpreAnswered("true");
                } else {
                    toast.error(t('Failed to send form'));
                }
            } catch (error) {
                console.log(error);
            }

        } else if (page == 1) {
            try {
                const response = await axios.patch(process.env.REACT_APP_BACKEND + `training/uctotal-players-pse/${uctotalID}`, {
                    pse: respostas.posTreinoCampo.percecaoEsforco,
                }, {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authTokens.access}` }
                });
                if (response.status === 200) {
                    // setPage(3);
                    setUctotalAnswered("true");
                } else {
                    toast.error(t('Failed to send form'));
                }
            } catch (error) {
                console.log(error);
            }
        } else if (page == 2) {
            try {
                const response = await axios.patch(process.env.REACT_APP_BACKEND + `training/ucgym-players-pse/${ucgymID}`, {
                    pse: respostas.posTreinoGym.percecaoEsforco,
                }, {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authTokens.access}` }
                });
                if (response.status === 200) {
                    // setPage(3);
                    setUcgymAnswered("true");
                } else {
                    toast.error(t('Failed to send form'));
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleFormChange = (formType, label, value) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [formType]: {
                ...prevValues[formType],
                [label]: value,
            },
        }));
    };

    const handleQuestionType = (n, name) => {
        setPage(n)
        setName(name)
    }

    const handleGoBackClick = () => {
        window.location.href = "/webapp/player/forms";
    }

    const handleGoNextClick = () => {
        if(page===2)
            window.location.href = "/webapp/player/forms";
        setPage(page + 1);
    }
        

    const changePassword = () => {
        const newPassword = { "password": passwordRef.current };
        if (passwordRef.current !== null) {
            try {
                axios.patch(process.env.REACT_APP_BACKEND + 'users/change-password', newPassword, { headers })
                    .then((response) => {
                        setPassword(false)
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            console.log("Passwords don't match")
        }
    }
    const updatePassword = (newValue) => {
        passwordRef.current = newValue;
    };
    const openPassword = () => {
        setPassword(true)
        //deactivate the scroll in the background
        document.body.style.overflow = 'hidden';
    }
    return (
        <div className="page-confirm-player">
            {password && <Password setPassword={setPassword} changePassword={changePassword} password={passwordRef} upDatePass={updatePassword} />}
            <NavbarJogador setPassword={openPassword} />
            <div className="questionario-type-container">
                <div className='back-logo'>
                    <img src={questionArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleGoBackClick} />
                    <div className="back-logo-text">{t('Forms')}</div>
                </div>
                <div className="webapp-playerforms-buttons">
                    <button className={page == 0 ? "webapp-playerforms-button webapp-playerforms-button-active" : "webapp-playerforms-button"} onClick={() => handleQuestionType(0, 'Pre-Training')}>{t('Pre-Training')}</button>
                    <button className={page == 1 ? "webapp-playerforms-button webapp-playerforms-button-active" : "webapp-playerforms-button"} onClick={() => handleQuestionType(1, 'Post-Training')}>{t('Post-Training')}</button>
                    <button className={page == 2 ? "webapp-playerforms-button webapp-playerforms-button-active" : "webapp-playerforms-button"} onClick={() => handleQuestionType(2, 'Post-Gym')}>{t('Post-Gym')}</button>
                </div>
            </div>
            {
                page == 0 && ucpre_answered == "false" ?
                <div className="forms-container">
                    <Forms labels={{ title: t('Sleep Quality'), bad: t('No Sleep'), mid: t('Moderate'), good: t('Excellent') }} onChange={(formType, value) => handleFormChange(formType, 'qualidadeSono', value)} formType="preTreino" initialValue={selectedValues.preTreino.qualidadeSono} />
                    <Forms labels={{ title: t('Readiness'), bad: t('Not Available'), mid: t('Moderate'), good: t('Totally Available') }} onChange={(formType, value) => handleFormChange(formType, 'disponibilidade', value)} formType="preTreino" initialValue={selectedValues.preTreino.disponibilidade} />
                    <Forms labels={{ title: t('Fatigue'), bad: t('Too Exausted'), mid: t('Moderate'), good: t('No Fatigue') }} onChange={(formType, value) => handleFormChange(formType, 'fadiga', value)} formType="preTreino" initialValue={selectedValues.preTreino.fadiga} />
                    <Forms labels={{ title: t('Muscle Soreness'), bad: t('Very Sore'), mid: t('Moderate'), good: t('No Soreness') }} onChange={(formType, value) => handleFormChange(formType, 'catedral', value)} formType="preTreino" initialValue={selectedValues.preTreino.catedral} />
                    <button className="webapp-playerforms-button webapp-playerforms-button-enviar" onClick={handleEnviarQuestionario}>{t('Send Form')}</button>
                </div>
                : page == 1 && uctotal_answered == "false" ? 
                <div className="forms-container">
                    <Forms labels={{ title: t("PSE"), bad: t('Resting'), mid: t('Hard'), good: t('Extremely Hard')}} onChange={(formType, value) => handleFormChange(formType, 'percecaoEsforco', value)} formType="posTreinoCampo" initialValue={selectedValues.posTreinoGym.percecaoEsforco} />
                    <button className="webapp-playerforms-button webapp-playerforms-button-enviar" onClick={handleEnviarQuestionario}>{t('Send Form')}</button>
                </div> 
                : page == 2 && ucgym_answered == "false" ?
                <div className="forms-container">
                    <Forms labels={{ title: t("PSE"), bad: t('Resting'), mid: t('Hard'), good: t('Extremely Hard') }} onChange={(formType, value) => handleFormChange(formType, 'percecaoEsforco', value)} formType="posTreinoGym" initialValue={selectedValues.posTreinoCampo.percecaoEsforco} />
                    <button className="webapp-playerforms-button webapp-playerforms-button-enviar" onClick={handleEnviarQuestionario}>{t('Send Form')}</button>
                </div> 
                :
                <div className="sucess-box">
                    <img src={sucessLogo} alt='' />
                    <div className="sucess-box-text">
                        {t('Your form')} {t(name)} {t('was sent successfully')}
                    </div>
                    <hr className="sucess-box-linha" />
                    <div className="webapp-home-page-buttons">
                        <button className="webapp-playerforms-button-continuar" onClick={handleGoNextClick}>{t('Continue')}
                            <img className='seta-img' src={seta} />
                        </button>
                    </div>
                </div>
            }
            {/* {page == 0 && ucpre_answered == "false" ?
                <div className="forms-container">
                    <Forms labels={{ title: t('Sleep Quality'), bad: t('No Sleep'), mid: t('Moderate'), good: t('Excellent') }} onChange={(formType, value) => handleFormChange(formType, 'qualidadeSono', value)} formType="preTreino" initialValue={selectedValues.preTreino.qualidadeSono} />
                    <Forms labels={{ title: t('Not Available'), bad: t('No Sleep'), mid: t('Moderate'), good: t('Totally Available') }} onChange={(formType, value) => handleFormChange(formType, 'disponibilidade', value)} formType="preTreino" initialValue={selectedValues.preTreino.disponibilidade} />
                    <Forms labels={{ title: t('Fatigue'), bad: t('Too Exausted'), mid: t('Moderate'), good: t('No Fatigue') }} onChange={(formType, value) => handleFormChange(formType, 'fadiga', value)} formType="preTreino" initialValue={selectedValues.preTreino.fadiga} />
                    <Forms labels={{ title: t('Muscle Soreness'), bad: t('Very Sore"'), mid: t('Moderate'), good: t('No Soreness') }} onChange={(formType, value) => handleFormChange(formType, 'catedral', value)} formType="preTreino" initialValue={selectedValues.preTreino.catedral} />
                    <button className="webapp-playerforms-button webapp-playerforms-button-enviar" onClick={handleEnviarQuestionario}>{t('Send Form')}</button>
                </div>
                : page == 1 && ucgym_answered == "false" ?
                    <div className="forms-container">
                        <Forms labels={{ title: t("PSE"), bad: t('Resting'), mid: t('Hard'), good: t('Extremely Hard')}} onChange={(formType, value) => handleFormChange(formType, 'percecaoEsforco', value)} formType="posTreinoGym" initialValue={selectedValues.posTreinoGym.percecaoEsforco} />
                        <button className="webapp-playerforms-button webapp-playerforms-button-enviar" onClick={handleEnviarQuestionario}>{t('Send Form')}</button>
                    </div>
                    : page == 2 && uctotal_answered == "false" ?
                        <div className="forms-container">
                            <Forms labels={{ title: t("PSE"), bad: t('Resting'), mid: t('Hard'), good: t('Extremely Hard') }} onChange={(formType, value) => handleFormChange(formType, 'percecaoEsforco', value)} formType="posTreinoCampo" initialValue={selectedValues.posTreinoCampo.percecaoEsforco} />
                            <button className="webapp-playerforms-button webapp-playerforms-button-enviar" onClick={handleEnviarQuestionario}>{t('Send Form')}</button>
                        </div>
                        : page == 3 ?
                            <div className="sucess-box">
                                <img src={sucessLogo} alt='' />
                                <div className="sucess-box-text">
                                    {t('Your Form')} {name} {t('was sent successfully')}
                                </div>
                                <hr className="sucess-box-linha" />
                                <div className="webapp-home-page-buttons">
                                    <button className="webapp-playerforms-button-continuar" onClick={handleGoNextClick}>{t('Continue')}
                                        <img className='seta-img' src={seta} />
                                    </button>
                                </div>
                            </div>
                            :
                            <div className="questionario-sent questionario-sent-text">
                                {t('This Form was alread answered')}
                            </div>
            } */}
        </div>
    )
}

export default FormsPlayer;