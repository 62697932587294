import "./CoachProfile.css"
import { useState, useEffect, useContext, useRef } from "react";

import Modal from 'react-modal';
import Avatar from "react-avatar-edit";

import DiscountPopup from "../../webAppComponents/DiscountPopup/DiscountPopup";
import Popup from "../../webAppComponents/ConfirmationPopup/Popup";
import ConfirmPasswordPopup from "../../webAppComponents/ConfirmPasswordPopup/ConfirmPasswordPopup";
import UserInfo from "./UserInfo"
import PlanInfo from "./PlanInfo";
import Password from "./Password";
import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import TextPlaceHolder from "../../webAppComponents/PlaceHolders/PlaceHolders";
import ImagePlus from "../../webAppAssets/image-plus.svg"

import EditPencil from "../../webAppAssets/edit-pencil.svg"
import cardicon from "../../webAppAssets/card-icon.svg"

import axios from 'axios';
import { Link } from "react-router-dom";
import AuthContext from '../../context/AuthContext'
import { useTranslation } from "react-i18next";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"
import { toast } from "react-toastify";

const CoachProfile = () => {
    const { t, i18n } = useTranslation(['coach-profile']);
    const [coachData, setCoachData] = useState([]);
    const [coachImage, setCoachImage] = useState(null);
    const labels = [
        t('Name'),
        t('Surname'),
        t('Email'),
    ];
    const [subscription, setSubscription] = useState([]);
    const [notify, setNotify] = useState(false);
    const [popup, setPopup] = useState(false);
    const [change, setChange] = useState(false);
    const [password, setPassword] = useState(false);
    const [language, setLanguage] = useState(i18n.language);
    const [passwordPopup, setPasswordPopup] = useState(false);
    const [discountPopup, setDiscountPopup] = useState(false);
    const [value, setValue] = useState(false);

    const [reload, setReload] = useState(false);
    //ref to store password
    const passwordRef = useRef(null);
    //ref to file
    const fileInputRef = useRef(null);

    const updatePassword = (newValue) => {
        // You can update the value of the ref here
        passwordRef.current = newValue;
    };


    const { authTokens, logoutUser } = useContext(AuthContext);

    // Define the headers with the Bearer token
    const headers = {
        Authorization: `Bearer ${authTokens.access}`,
    };

    const handleLanguage = (language) => {
        if (language !== i18n.language) {
            i18n.changeLanguage(language)
            setLanguage(language)
            updateLanguage(language)
        }
    }

    const updateLanguage = (language) => {
        axios.patch(process.env.REACT_APP_BACKEND + 'users/edit-user', { language }, { headers })
            .then((response) => {
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    // Get the user info from the backend
    useEffect(() => {
        // Make a GET request with the specified headers
        axios.get(process.env.REACT_APP_BACKEND + 'users/subscription', { headers })
            .then((response) => {
                // Handle the successful response here
                // Delete local storage image
                setCoachImage(() => {
                    let fullImageUrl = null;

                    if (response.data.image_url !== null)
                        fullImageUrl = process.env.REACT_APP_BACKEND + response.data.image_url;

                    return fullImageUrl;
                });
                setCoachData(response.data.user)
                setSubscription(response.data.subscription)
                if (response.data.subscription.default_payment_method == null) {
                    if (sessionStorage.getItem('defaultPaymentNotif') !== 'false') {
                        setNotify(true);
                    }
                }
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    }, [])

    useEffect(() => {
        if (notify) {
            toast.info(t('No payment method defined. Please add one.'), {
                autoClose: 5000,
                progress: undefined,
                position: "top-center",
                onClick: () => {
                    window.location.href = '/webapp/cardhistory';
                }
            });
            sessionStorage.setItem('defaultPaymentNotif', 'false');
            setNotify(false);
        }
    }, [notify, t]);

    // Get info from the inputs
    const getNewInfo = () => {
        const inputs = document.querySelectorAll(".webapp-coachprofile-userinfo-input")
        //compare the inputs with the coachData
        setChange(false)
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].value !== coachData[inputs[i].name]) {
                setChange(true)
            }
        }
    }

    // Send updated info to the backend
    const updateInfo = () => {

        const inputs = document.querySelectorAll(".webapp-coachprofile-userinfo-input")
        const newInfo = {}
        for (let i = 0; i < inputs.length; i++) {
            newInfo[inputs[i].name] = inputs[i].value
        }

        try {
            axios.patch(process.env.REACT_APP_BACKEND + 'users/edit-user', newInfo, { headers })
                .then((response) => {
                    // Handle the successful response here
                    newInfo["language"] = language
                    setCoachData(newInfo)
                    setChange(false)
                    //disable the inputs
                    for (let i = 0; i < inputs.length; i++) {
                        inputs[i].disabled = true
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    //Function to open the password popup
    const openPassword = () => {
        if(password === true){
            setValue(true)
        } else {
            setValue(false)
        }
        setPassword(false)
        setPopup(false)
        setPasswordPopup(true)
        setDiscountPopup(false)
    }

    // Cancel subscription
    const cancelSubscription = () => {
        try {
            axios.patch(process.env.REACT_APP_BACKEND + "users/cancel-subscription", {} ,{ headers })
                .then((response) => {
                    // Handle the successful response here
                    window.location.reload();
                })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
                toast.error(t('Something went wrong. Please try again later.'));
            });
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleCancelChange = () => {
        axios.patch(process.env.REACT_APP_BACKEND + 'users/cancel-change-subscription', {}, { headers })
            .then((response) => {
                // Handle the successful response here
                window.location.reload();
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
                toast.error(t('Something went wrong. Please try again later.'));
            });
    };
    

    // Function to change the password and send it to the backend
    const changePassword = () => {
        const newPassword = { "password": passwordRef.current };

        if (passwordRef.current !== null) {
            try {
                axios.patch(process.env.REACT_APP_BACKEND + 'users/change-password', newPassword, { headers })
                    .then((response) => {
                        // Handle the successful response here
                        setPassword(false)
                        window.location.reload();
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            console.log("Passwords don't match")
        }
    }

    const [showModal, setShowModal] = useState(false);
    

    async function handleImageUpload(file) {
        try {
            // Create FormData object and append the blob
            const formData = new FormData();
            formData.append("profile_image", file);

            // If file larger than 5MB, return
            if (file.size > 5242880) {
                console.log("File too large");
                return;
            }

            // Make a PATCH request to save the image to the backend
            const response = await axios.patch(process.env.REACT_APP_BACKEND + 'users/edit-coach-image', formData, { headers });

            if (response.status === 200) {
                // Handle the successful response here
                setReload(prevReload => !prevReload);
                

                // Assuming setCoachImage is a state updater function
                setCoachImage(URL.createObjectURL(file));
                return;
            }
        } catch (error) {
            // Handle the error here
            console.error("Error uploading image:", error);
        }
    }


    async function onCrop(croppedImage) {
        try {
            setCoachImage(croppedImage);
            // Extract base64 data from data URI
            var base64Data = croppedImage.split(',')[1];
            // Convert base64 to binary
            var binaryData = atob(base64Data);
            // Create ArrayBuffer and Uint8Array
            var arrayBuffer = new ArrayBuffer(binaryData.length);
            var uint8Array = new Uint8Array(arrayBuffer);
            for (var i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }
            // Create Blob
            var blob = new Blob([uint8Array], { type: 'image/png' });
            // Call the async function to handle image upload
            await handleImageUpload(blob);

        } catch (error) {
            // Handle the error here
            console.error("Error cropping image:", error);
        }
    }

    const handleImageChange = () => {
        setShowModal(true);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: "0",
        },
    };


    function closeModal() {
        if (showModal) {
            window.location.reload();
        }
        setShowModal(false);
    }
    
    const onClose = () => {
        closeModal();
    }

    const openDiscountPopup = () => {
        return () => {
            setDiscountPopup(true);
        }
    }
    
    const applyDiscount = async (promoCode, setErrorState, setErrorMessage) => {
        if (promoCode === "") {
            setErrorState(true);
            setErrorMessage(t('Please insert a coupon'));
            return;
        }
    
        try {
            const response = await axios.post(
                process.env.REACT_APP_BACKEND + 'users/update-subscription',{ promoCode },{ headers });
    
            if (response.status === 200) {
                setErrorState(false);
                setErrorMessage("");
                setDiscountPopup(false);
                toast.success(t('Discount applied successfully!',{
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                }));
            }
        } catch (error) {
            //console.log(error);
            setErrorState(true);
            if (error.response.status === 400) {
                const errorData = error.response.data.error;
                setErrorMessage(t(errorData));
            }
            else {
                setErrorMessage(t('Something went wrong. Please try again later.'));
            }
        }
    };
    

    //function to place the background images in the correct position
    const placeBackgroundImages = () => {
        const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
        const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
        const bckLogo1Height = bckLogo1.offsetHeight;
        bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
        bckLogo2.style.top = `0%`;
        const bckLogo1Width = bckLogo1.offsetWidth;
        bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
        bckLogo2.style.left = `75%`;
    }

    useEffect(() => {
        placeBackgroundImages();
        window.addEventListener('resize', placeBackgroundImages);
        return () => {
            window.removeEventListener('resize', placeBackgroundImages);
        }
    }, [])

    return (
        <div className="webapp-coachprofile">
            <Sidebar selectedOption="profile" reload={reload} />
            <div className="webapp-coachprofile-content">
                <div className="webapp-create-team-background-images">
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                    <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
                </div>
                <button className="webapp-coachprofile-logout" onClick={logoutUser}>{t('Log Out')}</button>
                <div className="webapp-coachprofile-header">
                    {coachImage === null ?
                        <div className="webapp-coachprofile-header-img-null">
                            <img className='webapp-coachprofile-img-null' src={ImagePlus} alt={`Profile image of ${coachData.first_name}`} onClick={handleImageChange} />
                        </div>
                        :
                        <div className="webapp-coachprofile-header-img">
                            <img className="webapp-coachprofile-img" src={coachImage} alt={`Profile image of ${coachData.first_name}`} onClick={handleImageChange} />
                        </div>
                    }
                    {showModal && (
                        <Modal
                            isOpen={showModal}
                            onRequestClose={() => setShowModal(false)}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <Avatar
                                width={390}
                                height={295}
                                onCrop={onCrop}
                                onClose={onClose}
                                label={t('Choose a file')}
                            />
                        </Modal>
                    )}
                    <div className="webapp-coachprofile-header-text">
                        <h1>{t('Coach')}</h1>
                        {coachData.first_name === undefined ?
                            <TextPlaceHolder width={'50%'} />
                            :
                            <p>{`${coachData.first_name} ${coachData.last_name}`}</p>
                        }
                    </div>

                </div>
                <div className="webapp-coachprofile-body">
                    <div className="webapp-coachprofile-body-section webapp-coachprofile-body-changeinfo">
                        <h2 className="webapp-coachprofile-body-sectiontitle">{t('Personal Data')}</h2>
                        {coachData.first_name === undefined ?
                            <div className="webapp-coachprofile-data" onChange={getNewInfo}>
                                <TextPlaceHolder fontSize={2} />
                                <TextPlaceHolder fontSize={2} />
                                <TextPlaceHolder fontSize={2} />
                            </div>
                            :
                            <div className="webapp-coachprofile-data">
                                <div className="webapp-coachprofile-data" onChange={getNewInfo}>
                                    {Object.keys(coachData).filter(key => key !== 'id').map((key, index) => { 
                                        // sometimes coachData may come with an id, by filtering it out we prevent the troubles with different indexes number
                                        if (key === "first_name" || key === "last_name"){ 
                                            return (<UserInfo key={index} label={labels[index]} value={coachData[key]} name={key} canEdit={true} />)
                                        } 
                                        // you can't edit your email
                                        if (key === "email"){
                                            return (<UserInfo key={index} label={labels[index]} value={coachData[key]} name={key} canEdit={false} />)
                                        }
                                        if (key === "language") {
                                            return (
                                                <div key={index} className="webapp-coachprofile-languages-options">
                                                    <button className={`webapp-coachprofile-userinfo-input-language ${language === 'pt' ? 'language-selected' : ''}`} name={key} onClick={() => handleLanguage("pt")}>
                                                        PT
                                                    </button>
                                                    <button className={`webapp-coachprofile-userinfo-input-language ${language === 'es' ? 'language-selected' : ''}`} name={key} onClick={() => handleLanguage("es")} >
                                                        ES
                                                    </button>
                                                    <button className={`webapp-coachprofile-userinfo-input-language ${language === 'en' ? 'language-selected' : ''}`} name={key} onClick={() => handleLanguage("en")} >
                                                        EN
                                                    </button>
                                                </div>
                                            )
                                        }
                                        else
                                            return null
                                    })}
                                </div>
                                <button className="webapp-coachprofile-password-button" onClick={() => setPassword(true)}> {t('Password')} <img src={EditPencil} /></button>
                                {password && <Password setPassword={setPassword} changePassword={openPassword} upDatePass={updatePassword} />}
                                {change && <button className="webapp-coachprofile-planinfo-button webapp-coachprofile-planinfo-savechanges" onClick={updateInfo}>{t('Save Changes')}</button>}
                            </div>
                        }
                    </div>
                    <div className="webapp-coachprofile-body-section">
                        <h2 className="webapp-coachprofile-body-sectiontitle">{t('Active Plan')}</h2>
                        <PlanInfo popup={setPopup} plan={subscription} />
                        {subscription.metadata && subscription.metadata.new_price && subscription.cancel_at_period_end !== true ?
                            <div className="webapp-coachprofile-planinfo-footer">
                                <div className="webapp-coachprofile-planinfo-footer-options">
                                    <button onClick={handleCancelChange} className="webapp-coachprofile-body-button">{t('Cancel action')}</button>
                                    <Link to="/webapp/changeplan" className="webapp-coachprofile-body-button">{t('Change Plan')}</Link>
                                </div>
                                <span className="webapp-coachprofile-planinfo-plan-warning"> {t('Your plan will change to the ' + subscription.metadata.new_plan_period + ' on')} {subscription.current_period_end}  </span>
                            </div>
                        :
                            null
                        }

                        {subscription.cancel_at_period_end === true ?
                            <div className="webapp-coachprofile-planinfo-footer">
                                <button onClick={handleCancelChange} className="webapp-coachprofile-body-button">{t('Cancel action')}</button>
                                <span className="webapp-coachprofile-planinfo-plan-warning"> {t('Your plan will be cancel at ' + subscription.current_period_end)}  </span>
                            </div>
                        :
                            <Link to="/webapp/changeplan" className="webapp-coachprofile-body-button">{t('Change Plan')}</Link>
                        }
                        <button onClick={openDiscountPopup()} className="webapp-coachprofile-body-button">{t('Apply Discount')}</button>
                        {discountPopup && (
                            <DiscountPopup
                                setState={setDiscountPopup}
                                action={(promoCode, setErrorState, setErrorMessage) =>
                                    applyDiscount(promoCode, setErrorState, setErrorMessage)
                                }
                                actionType={"applyDiscount"}
                            />
                        )}
                    </div>
                    <div className="webapp-coachprofile-body-section">
                        <h2 className="webapp-coachprofile-body-sectiontitle">{t('Payment Method')}</h2>
                        {subscription.default_payment_method === undefined ?
                            <div className="webapp-coachprofile-paymentdata">
                                <div className="webapp-coachprofile-paymentdata-info">
                                    <h3 className="webapp-coachprofile-paymentdata-title">{t('Current Card')}</h3>
                                    <TextPlaceHolder fontSize={2} />
                                </div>
                                <Link to="/webapp/cardhistory" className="webapp-coachprofile-paymentdata-button"><img src={cardicon} /><h4>{t('Change Card')}</h4></Link>
                            </div>
                            :
                            <div className="webapp-coachprofile-paymentdata">
                                <div className="webapp-coachprofile-paymentdata-info">
                                    <h3 className="webapp-coachprofile-paymentdata-title">{t('Current Card')}</h3>
                                    <input className="webapp-coachprofile-userinfo-input webapp-coachprofile-payment-input" type="text" placeholder={subscription.default_payment_method === null ? t('Not Defined') : `**** **** **** ${subscription.default_payment_method?.card.last4}`} disabled />
                                </div>
                                <Link to="/webapp/cardhistory" className="webapp-coachprofile-paymentdata-button"><img src={cardicon} /><h4>{t('Change Card')}</h4></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {popup && <Popup data={{
                image: "close",
                message: [t("cancel-plan-text"), t("cancel-plan-need-help"), t("cancel-plan-proceed")],
                title: `${t("cancel-plan")}?`,
                close: t("go-back"),
                advance: t("Confirm")
            }}
                setState={setPopup}
                action={openPassword} />
            }
            {passwordPopup && <ConfirmPasswordPopup
                setState={setPasswordPopup}
                action={value ? changePassword : cancelSubscription}
                actionType={value ? 'changePassword' : 'cancelSubscription'} />
            }
        </div>
    )
}

export default CoachProfile;